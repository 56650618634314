.view {

    .sidebar-right .sidebar-header.legend-header-info H1,
    .sidebar-right .sidebar-header H1 {
        line-height: 100% !important;
        padding-top: 3px;
    }
}

.view .switcher {
    // font-size: 2rem;
    width: 3rem;
    padding-bottom: 0.5rem;
    border-bottom-left-radius: 1.4rem;

    .switch-item {
        width: 3rem;
        height: 2.5rem;
    }

    .switch-item {
        span.picto {
            display: block;
            margin: 0.5rem;
            width: 2rem;
            height: 2rem;
            border: 2px solid transparent;
            border-radius: 3rem;
        }

        &.active span.picto {
            border-color: @activeColor;
            background-color: @activeColor;

            &:before {
                color: @white;
            }
        }

        &:hover span.picto {
            border-color: @activeColor;
        }
    }

    .switch-item .picto:before {
        font-size: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @activeColor;
    }
}

.view .control-box {
    bottom: calc(~'16px + (@{margin}/2)');
}

.view .map-wrapper .map-scale {
    right: 0px;
    left: initial;
    border-right: 0px;
    // border-left: @borderDark;
    padding-left: 0.5rem;
    border-top-left-radius: 0.5rem;

}

.view .vertical-split .map-wrapper .control-box {
    flex-direction: row;
    width: auto;

    >* {
        margin-left: (@margin / 2);
    }
}

H1.pitch {
    font-size: 3rem !important;

    * {
        line-height: inherit;
    }
}

.pitch.sub-pitch {
    text-transform: initial;
    font-size: 1.2rem !important;
}

.main .map-navigator .sidebar .input-wrapper {
    font-size: 1.2rem;
}

.print-block {
    .a3 {
        display: none;
    }
}