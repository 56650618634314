input,
input[type='email'],
input[type='password'],
input[type='search'],
input[type='url'],
input[type='text'],
input[type='number'],
textarea {
    font-family: @gui-font;
    border: 1px solid @inputBorderColor;
    font-size: 1em;
    padding: 0.3em;
    max-width: 100%;
    min-width: 0;
    box-sizing: border-box;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
        color: @inputPlaceholderColor;
    }
}

//this is used when a CLEAR_INPUT_TEXT value is needed, and to not show it..
input.clear {
    color: transparent;
}

/// input color

input[type='color'] {
    padding: 0px;
    padding-right: 0px !important;
    height: 25px;
    // width: calc(~'50% - @{small-margin} + 2px');
}

input[type='color'] {
    -webkit-appearance: none;
    border: none;
}

input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type='color']::-webkit-color-swatch {
    border: 1px solid @inputBorderColor;
}

input[type='range'] {
    padding: 0px;
    padding-right: 0px !important;
}



// input text

.input__wrapper--text {
    width: 100%;
    display: flex;

    input {
        width: 100%;
        padding-right: 3ch;
    }

    button.input-ok-button {
        .fa();
        margin-left: -2ch;
        color: transparent;
        padding: 0.3em;
    }

    &:focus-within {
        button.input-ok-button {
            color: @disabledColor;

            &:hover {
                color: @activeColor;
            }
        }
    }
}