.loader-anim,
.splash-spiner {
    margin: @margin * 2 auto;
    display: flex;
    flex-direction: column;

    &:after {
        // .awsomefont-spin;
        .fa();
        .fa-spin();
        color: @loaderColor;
        content: @fa-var-circle-notch;
        font-size: 48px;
    }
}

.loader-anim__small {
    &:before {
        // .awsomefont-spin;
        .fa();
        .fa-spin();
        color: @loaderColor;
        content: @fa-var-circle-notch;
        font-size: 18px;
    }
}

.loader__small {
    .textExtraSmall() !important;
}
