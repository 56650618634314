// campaign answer 

.observation-wrapper-form {
    display: flex;
    flex-direction: column;

    H2 {
        margin-bottom: @margin;
    }

    // .observation-wrapper-map-form {
    //     position: relative;
    //     margin: @margin 0;
    //     margin-bottom: 3.25rem;
    //     height: 100%;

    //     .map-wrapper {
    //         height: 100%;
    //     }
    // }
    // button.add-observation,
    // button.validate-location {
    //     // position: relative;
    //     margin-top: @margin;
    // }
}

.observation-wrapper-form .form-header {
    margin-top: @margin;
}

.annotate-observation {
    margin-bottom: @margin;

    .btn {
        margin-right: @margin;
    }
}

.answer__wrapper {

    border: 2px solid @greyMedium;
    border-radius: 2em;
    padding: @margin;
    margin-bottom: @margin;
    background-color: #eee;

    .question {
        margin-top: 0px !important;
    }

    .annotate-answer {

        .btn {
            background-color: #fff;
            padding: 0 @margin*0.5;
            border-radius: 2rem;
            color: @activeColor;
            margin-left: @margin;
            width: fit-content;

            &:last-child {
                width: 32px; //fixME
            }
        }
    }

    .choice__wrapper.selected {
        background-color: @activeColor;
        color: #fff;
    }
}

.answer-form__body {
    .collect-head__wrapper .helptext {

        margin-top: 0.3rem;
    }

    .timestamp-input {

        details {
            margin-top: @margin*4;
            margin-bottom: @margin*2;
            padding-left: calc(~"@{smallSidebarMobile} + @{margin} + @{margin-mob}");
        }

        summary {
            width: fit-content;
            color: @activeColor;
            padding: 1em;
            border: 1px solid @activeColor;
            border-radius: @borderRadius;
            margin-bottom: @margin*0.5;

            &:hover,
            &:focus {
                color: #fff;
                background-color: @activeColor;
            }

            &:after {
                display: none;
            }

            .icon {
                margin-left: 0.5em;
                // display: none
            }

        }

        input {
            margin-right: @margin;
            margin-bottom: @margin*0.5;
        }
    }
}