.header {
    .flex(center, initial, initial);
    padding: 0 @margin;
    font-size: 80%;

    >div {
        .flex(center, initial, initial);
        height: @headerH;
    }

    * {
        font-size: inherit !important;
        color: @textColor;
    }

    a:hover {
        box-shadow: initial;
    }

    .icon {
        margin-right: @small-margin ;
        color: @activeColor;
    }

    summary:hover,
    button:hover,

    .brand-logo {
        >div {
            height: 35px;
            background-position: center left;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .brand-name {
            background-image: url('../assets/imgs/logo.svg');
            width: 95px;
            margin-right: 0px;
            margin-bottom: 3px;
        }
    }

    .app-title {
        font-weight: @fwSemiBold;
        font-size: 15px !important;
        letter-spacing: -0.5px;
        margin-left: 1ch;

        &:before {
            .fas();
            content: @fa-var-chevron-right;
            font-size: inherit;
            color: inherit;
            width: 1em;
            height: 1em;
            margin-right: 0.5em;
        }
    }


    .lang-switch,
    .navigate {
        margin-left: @margin;
        // text-transform: uppercase;

        &:before {
            .fa();
            margin-right: @small-margin  !important;
            color: @activeColor;
        }

        &:hover {
            color: @textColor;

            &:before {
                color: @textColor;
            }
        }
    }

    .app-listwrapper {
        .absolute(0px, calc(~'@{panel-rightW} + 25px'), auto, auto);

        .icon {
            margin-right: @small-margin ;
        }
    }

    .header-toolbar {
        .absolute(0px, @margin, auto, auto);
        .transition;
        z-index: 99999999;
        cursor: default;

        a {
            color: @textColor;
            text-decoration: none;
            cursor: default;
        }

        .navigate.login {
            margin-left: 0px;
        }

        /// USER DRAWER

        .user {
            display: flex;

            details {
                margin-right: @margin;
                position: relative;

                summary {
                    margin: 0px;
                    height: 100%;

                    &::after {
                        display: none;
                    }

                    .menu-label {
                        margin-right: @margin*0.5
                    }
                }

                .details-tail {
                    .absolute(initial, initial, initial, -@appSelectPadding * 0.5);
                    background-color: white;
                    padding: (@appSelectPadding / 2);
                    border: 0px;
                    -webkit-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
                    -moz-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
                    box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    min-width: 25ch;

                    .app-select {
                        margin-top: @margin;

                        .tail {
                            margin-top: @margin*0.5;
                            padding-left: 2em;

                            .app-item {
                                display: block;
                                padding: @margin*0.3 0;
                            }
                        }
                    }

                    .documentation,
                    .user-details__wrapper,
                    .login {
                        margin-top: @margin*0.75;
                        border-top: @borderLight ;
                        padding-top: @margin*0.75;

                        .contact {
                            padding-top: @margin*0.3;
                        }

                        .contact:hover {
                            color: @textColor;

                            .icon {
                                color: @textColor;
                            }
                        }

                        .help:hover {
                            color: @textColor;

                            .icon {
                                color: @textColor;
                            }
                        }
                    }
                }
            }
        }

        .navigate.user::before {
            content: @fa-var-user;
        }

    }
}