.checkbox__wrapper label {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
        .focusVisible();
        display: flex;
        align-items: center;
        appearance: none;
        font-size: 1.25em;
        margin: 0;
        margin-left: 1ch;
        border: 0px;


        &:after {
            .far();
            content: @fa-var-square;
            color: @textColor;
        }

        &:checked:after {
            content: @fa-var-check-square;
        }
    }
}

// /////// OLD CHECKBOXES --> TO BE FIXED ?

// .checkbox {
//     cursor: default;

//     &:before {
//         .fa();
//         margin-right: @small-margin;
//     }
// }

// .checkbox {
//     &::before {
//         content: @fa-var-square;
//     }

//     &:hover:before,
//     &.active:before {
//         content: @fa-var-check-square;
//     }
// }

// /////// CHECKBOX à la main

// .checkbox {
//     &__item {
//         display: flex;
//         align-items: center;
//         .textRegular();
//         cursor: default;
//         min-height: 2em;
//     }

//     &__label {
//         &::first-letter {
//             text-transform: capitalize;
//         }
//     }

//     &__box {
//         flex-shrink: 0;
//         position: relative;
//         width: 1em;
//         height: 1em;
//         margin-right: 1em;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         overflow: hidden;

//         &::after,
//         &::before {
//             content: '';
//             width: 1px;
//             height: 150%;
//         }

//         &::before {
//             transform: rotate(45deg);
//         }

//         &::after {
//             transform: rotate(-45deg);
//         }
//     }
// }

// .checkbox__item {
//     .checkbox__box {
//         background-color: #fff;
//         border: 1px solid @activeColor;
//     }

//     &:hover .checkbox__box {
//         border: 1px solid @activeColorHover;

//         &::after,
//         &::before {
//             background-color: @activeColorHover;
//         }
//     }

//     &:active .checkbox__box {
//         border: 1px solid @activeColorPressed;

//         &::after,
//         &::before {
//             background-color: @activeColorPressed;
//         }
//     }

//     &.selected .checkbox__box {
//         border: 1px solid @activeColor;

//         &::after,
//         &::before {
//             background-color: @activeColor;
//         }
//     }
// }

// .checkbox__item.disabled {
//     color: @disabledColor;
//     border: 1px solid @disabledColor;

//     .checkbox__box {
//         background-color: @disabledColor;

//         &::after,
//         &::before {
//             background-color: @disabledColor;
//         }
//     }

//     &:hover .checkbox__box {
//         background-color: @disabledColor;

//         &::after,
//         &::before {
//             background-color: #fff;
//         }
//     }
// }

// // NOTES FOR ANIMATIONS
// // button transition :
// // :active trigger event on click PRESS
// // transition trigger event on clic RELEASE