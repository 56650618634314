.transition {
    transition: all 0.1s;
}

.transitionSlow {
    transition: all 0.3s;
}

.absolute(@top, @right, @bottom, @left) {
    position: absolute;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
}

.flex(@align: center, @justify: space-around, @wrap: wrap) {
    display: flex;
    align-items: @align;
    justify-content: @justify;
    flex-wrap: @wrap;
}

.shadow() {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
}

.no-shadow() {
    -webkit-box-shadow: initial;
    -moz-box-shadow: initial;
    box-shadow: initial;
}

@borderDark: 1px solid @borderDarkColor;
@borderLight: 1px solid @borderLightColor;

.border1px {
    border: @borderLight;
}

//AWSOMEFONT

.awsomefont {
    //copied from fa/less/core.less
    // display: inline-block;
    font: normal normal normal @fa-font-size-base / @fa-line-height-base
        forkawesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.awsomefont-spin {
    .awsomefont;
    display: inline-block;
    -webkit-animation: fa-spin 2s infinite linear;
    -moz-animation: fa-spin 2s infinite linear;
    -o-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

.awsomefont-burst(@time: 1s) {
    .awsomefont;
    display: inline-block;
    -webkit-animation: burst @time infinite linear;
    animation: burst @time infinite linear;
}

///

.legendItemPosition {
    width: @legend-itemW;
    margin-right: @margin;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
}

.interactive {
    .transition;
    cursor: default;

    &:hover {
        color: @activeColor;
    }
}

// .interactive-light {
//     .interactive;

//     &:hover {
//         color: lighten(@interactiveColorHover, 20%);
//     }
// }

// .interactive-dark {
//     .interactive;

//     &:hover {
//         color: darken(@interactiveColorHover, 20%);
//     }
// }

//APP WIDGET BACKGROUND

.widgetBackground {
    background-color: @bgLightColor;
    padding: @small-margin;
}

.widgetActiveBackground {
    .transition;
    border: 1px solid @bgLightColor;
    background-color: @bgLightColor;
    padding: @small-margin;
    margin-bottom: @small-margin;

    &:hover {
        background-color: @bgLightColor + 15%;
        border: 1px solid @bgLightColor + 15%;
        cursor: default;
    }

    &.active {
        background-color: @bgLightColor;
        border: 1px solid @bgLightColor;
    }
}

// TRIANGLE

@angle: 30deg;

// transform origin variables -> @cornerPosition
@cornerTopLeft: bottom left;
@cornerTopRight: bottom right;
@cornerBottomRight: top right;
@cornerBottomLeft: top left;

.triangleBase() {
    position: relative;
    z-index: 0;
    overflow: hidden;

    &:after {
        .absolute(0, 0, 0, 0);
        content: '';
        transform-origin: @cornerPosition;
        background-color: @t-color;
        z-index: -1;
    }
}

.triangle(@cornerPosition, @t-color) when (@cornerPosition =@cornerTopLeft),
(@cornerPosition =@cornerBottomRight) {
    .triangleBase();

    &:after {
        transform: rotate(-@angle);
    }
}

.triangle(@cornerPosition, @t-color) when (@cornerPosition =@cornerTopRight),
(@cornerPosition =@cornerBottomLeft) {
    .triangleBase();

    &:after {
        transform: rotate(@angle);
    }
}

// SCROLLBAR - will apply for browsers supporting scrollbar css specs

.scroll-mixin(@scroll-color: @inactiveColor) {
    scrollbar-width: thin;
    scrollbar-color: @scroll-color white;

    // will apply for webkit based browsers

    /* width */
    &::-webkit-scrollbar {
        width: @margin / 2;
        height: @margin / 2; //for horizontal scrollbar
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #fcfcfc;
        /* IE9, iOS 3.2+ */
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjAlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwLjQ5Ii8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuNSIvPjxzdG9wIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwLjUxIi8+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
        background-image: -webkit-gradient(
            linear,
            0% 0%,
            100% 0%,
            color-stop(0, rgb(255, 255, 255)),
            color-stop(0.49, rgb(255, 255, 255)),
            color-stop(0.5, rgb(0, 0, 0)),
            color-stop(0.51, rgb(255, 255, 255)),
            color-stop(1, rgb(255, 255, 255))
        );
        /* Android 2.3 */
        background-image: -webkit-repeating-linear-gradient(
            left,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 49%,
            rgb(0, 0, 0) 50%,
            rgb(255, 255, 255) 51%,
            rgb(255, 255, 255) 100%
        );
        /* IE10+ */
        background-image: repeating-linear-gradient(
            to right,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 49%,
            rgb(0, 0, 0) 50%,
            rgb(255, 255, 255) 51%,
            rgb(255, 255, 255) 100%
        );
        background-image: -ms-repeating-linear-gradient(
            left,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 49%,
            rgb(0, 0, 0) 50%,
            rgb(255, 255, 255) 51%,
            rgb(255, 255, 255) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff", endColorstr="#ffffffff", GradientType=1);
    }

    // /* Track */
    // &::-webkit-scrollbar-track {
    //     background: #000;
    //     background: #ffffff;
    //     /* Old browsers */
    //     background: -moz-linear-gradient(left, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* FF3.6-15 */
    //     background: -webkit-linear-gradient(left, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* Chrome10-25,Safari5.1-6 */
    //     background: linear-gradient(to right, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
    //     /* IE6-9 */
    // }
    // &::-webkit-scrollbar-track:horizontal {
    //     background: #000;
    //     background: #ffffff;
    //     /* Old browsers */
    //     background: -moz-linear-gradient(top, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* FF3.6-15 */
    //     background: -webkit-linear-gradient(top, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* Chrome10-25,Safari5.1-6 */
    //     background: linear-gradient(to bottom, #ffffff 49%, #000000 50%, #ffffff 51%);
    //     /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
    //     /* IE6-9 */
    // }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: @scroll-color;
        width: @margin / 2;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: @scroll-color;
    }
}

.activeBullet() {
    display: flex;

    &::before {
        content: ' ';
        flex-shrink: 0;
        border: 2px solid @terraLight;
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        margin-right: @margin;
        background-color: white;
    }

    &:hover::before {
        background-color: @terraDark;
    }

    &.active::before,
    &.selected::before {
        background-color: @greyDark;
    }
}
