.main.user {
    .breadcrumb {
        display: flex;
    }

    h2 {
        margin-bottom: @margin*0.5;
    }

    .btn-logout {
        margin-left: auto;
    }

    .note.text {
        margin-bottom: @margin;

        .md {
            padding-left: @margin;
        }
    }

    .note.image {
        margin-bottom: @margin;

        img {
            padding-left: @margin;
            margin-top: @margin*0.5;
        }
    }

    .o-list {
        button {
            margin-left: @margin;
        }
    }
}