.annotation__wrapper {
    animation-duration: 500ms;
    animation-name: slide-down;
}

@keyframes slide-down {
    from {
        margin-left: calc(~"-100% - @{smallSidebarMobile} - 4*@{margin}");
    }

    to {
        margin-left: 0%;
    }
}


.annotation__list {
    margin-top: @margin;

    .note.text p {
        margin-top: 0px !important;
    }

    .note.text:last-child p {
        margin: 0px !important;
    }

    .note.meta {
        margin-bottom: (@margin/2);
        text-transform: uppercase;
    }

    .note.image {
        .user-image {
            width: 100%;
            max-width: 300px;
            margin-bottom: (@margin/2);
            border-radius: 0.5em;
        }
    }

    .user-sound {
        max-width: 100%;
        margin-bottom: @margin;
    }
}