// header logos

.header {

    .brand-logo {
        border: 0px;
        margin-bottom: 0px;
        height: initial;
        max-height: initial;

        .brand-name {
            background-image: url('../assets/imgs/logo-smartwater-baseline.svg');
            width: 100px;
            height: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: (@margin/2);

            @media screen and (min-width: 900px) {
                width: 250px;
            }
        }
    }

    a.logo-cartofixer,
    .app-title {
        display: none !important;
    }


    .header-toolbar {
        button.lang-switch {
            background-color: @bodyBgColor;
            border: 0px;
        }
    }

    // disable tooltips in header, since it breaks the layout
    button:after {
        display: none;
    }
}