@media screen and (min-width: 900px) {
    .root.smartwater {

        .header,
        .main,
        .footer {
            position: relative;
            overflow: initial;
            top: initial;
            bottom: initial;
            right: initial;
            left: initial;
        }

        .header {
            .header-toolbar {
                border-bottom: 0px;
                position: relative;
                margin-left: auto;
            }
        }

        .main {
            margin-top: @margin;

            >div {
                height: initial;

            }

            .map-wrapper {
                height: 500px;
            }
        }

        .footer {
            width: 800px;
            margin: auto;
            margin-top: @margin;
            border-top: @borderLight;
            padding-top: @margin;
            padding-bottom: @margin*2;
            height: initial;
            height: fit-content;
            border: 0px;
        }

        .main {
            width: 800px;
            padding: @margin *2;
            border: 2px solid @activeColor;
            border-radius: 2rem;
            margin: auto;
        }

        H1 {
            width: initial;
        }

        details .btn {
            width: fit-content;
        }

    }




    // .header .brand-logo .brand-name {
    //     height: @headerH;
    // }
}