// .main.splash {
//     // position: absolute;
//     top: 35px;
//     right: 0;
//     bottom: 15px;
//     /* left: 15px; */
//     /* padding-right: 15px; */
//     overflow-x: hidden;
// }

.splash {
    // .absolute(@headerH, @margin, @margin, @margin);
    position: relative;
    width: calc(~'100% - 2 * @{margin}');
    height: 100%;
    margin: auto;
    padding: initial;
    padding-top: @margin;
    border-top: @borderLight;
    overflow-y: hidden;
    text-align: center;
    left: initial;

    .splash-content {
        margin-top: 16%;
        margin-bottom: 33px;
        text-align: center;

        h1 {
            text-transform: uppercase;
            font-weight: @fwRegular;
        }
    }
}
