// inpired by https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/


@track-color: @textColor;
@thumb-color: white;

@thumb-radius: @margin;
@thumb-height: @margin;
@thumb-width: @margin;
@thumb-shadow-size: 0px;
@thumb-shadow-blur: 0px;
@thumb-shadow-color: #111;
@thumb-border-width: 1px;
@thumb-border-color: @textColor;

@track-width: 100%;
@track-height: 3px;
@track-shadow-size: 0px;
@track-shadow-blur: 0px;
@track-shadow-color: #222;
@track-border-width: 1px;
@track-border-color: @track-color;

@track-radius: 5px;
@contrast: 30%;

.shadow(@shadow-size, @shadow-blur, @shadow-color) {
    box-shadow: @shadow-size @shadow-size @shadow-blur @shadow-color, 0px 0px @shadow-size lighten(@shadow-color, 5%);
}

.track() {
    width: @track-width;
    height: @track-height;
    cursor: pointer;
}

.thumb() {
    .shadow(@thumb-shadow-size, @thumb-shadow-blur, @thumb-shadow-color);
    border: @thumb-border-width solid @thumb-border-color;
    height: @thumb-height;
    width: @thumb-width;
    border-radius: @thumb-radius;
    background: @thumb-color;
    cursor: pointer;
}

input[type=range] {
    -webkit-appearance: none;
    margin: (@thumb-height/2) 0;
    width: @track-width;
    border: 0px;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        .track();
        .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
        background: @track-color;
        border-radius: @track-radius;
        border: @track-border-width solid @track-border-color;
    }

    &::-webkit-slider-thumb {
        .thumb();
        -webkit-appearance: none;
        margin-top: ((-@track-border-width * 2 + @track-height) / 2) - (@thumb-height / 2);
    }

    &:focus::-webkit-slider-runnable-track {
        // background: lighten(@track-color, @contrast);
        background: @activeColor
    }

    &::-moz-range-track {
        .track();
        .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
        background: @track-color;
        border-radius: @track-radius;
        border: @track-border-width solid @track-border-color;
    }

    &::-moz-range-thumb {
        .thumb();
    }

    &::-ms-track {
        .track();
        background: transparent;
        border-color: transparent;
        border-width: @thumb-width 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: darken(@track-color, @contrast);
        border: @track-border-width solid @track-border-color;
        border-radius: @track-radius*2;
        .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
    }

    &::-ms-fill-upper {
        background: @track-color;
        border: @track-border-width solid @track-border-color;
        border-radius: @track-radius*2;
        .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
    }

    &::-ms-thumb {
        .thumb();
    }

    &:focus::-ms-fill-lower {
        background: @track-color;
    }

    &:focus::-ms-fill-upper {
        background: lighten(@track-color, @contrast);
    }
}