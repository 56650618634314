.home-switch {
    margin: @margin*2 0;

    h2 {
        margin-bottom: @margin;
    }

    .switch__wrapper {
        display: flex;

        .btn-1 {
            width: initial;
            flex: 1;
            flex-direction: column;
            align-items: baseline;
            justify-content: flex-start;

            .label {
                margin-bottom: @margin * 0.5;
            }

            .illustration {
                width: 3rem;
                height: 3rem;
                background-size: contain;
                margin-top: auto;
            }

            &.community-btn {
                margin-right: @margin*0.5;

                .illustration {
                    background-image: url('../../assets/picto-community.svg');
                }
            }

            &.region-btn {
                margin-left: @margin*0.5;

                .illustration {
                    background-image: url('../../assets/picto-region.svg');
                }
            }
        }
    }

    .home-switch__illu {
        width: 100%;
        padding-bottom: 40%;
        background-image: url(../../assets/illu.jpg);
        background-position: center;
        background-size: cover;
        margin-top: @margin * 2;
        margin-bottom: @margin;
    }
}

.main.home .community:hover h2 {
    text-decoration: underline;
}

.community {
    margin-bottom: @margin * 3;
    display: flex;
    flex-direction: column;
    cursor: default;

    .picto-maps {
        display: flex;
        float: left;
    }

    .minimap__wrapper {
        display: block;
        width: 60px;
        height: 60px; // fallback for old browsers
        height: fit-content;
        // float: left;
        margin-right: @margin*0.5;
    }

    .community-img {
        display: block;
        padding: @margin * 0.5;
        width: 60px;
        height: 60px; // fallback for old browsers
        height: fit-content;

        &.region {
            width: 120px;
        }
    }

    .description {
        margin-top: @margin;
    }

    .btn {
        margin-top: @margin*0.5;
        margin-left: auto;
    }

    .contact {
        margin-top: @margin;
    }

    .collect__content {

        .content-header {
            display: flex;
            align-items: center;
            margin-bottom: @margin;

            * {
                margin: 0;
            }

            .affinity-box {
                margin-left: auto;

                .icon {
                    font-size: 120%;
                }
            }
        }
    }
}

.collect__item {
    max-width: 60rem;
    margin: @margin 0;
    padding: @margin;
    border: @borderLight;
    border-radius: @borderRadius * 1.5;

    .short-description {
        .md p:last-child {
            margin-bottom: 0px;
        }
    }

    .actions {
        margin: 0;
        // margin-top: @margin;
        margin-left: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;


        @media screen and (min-width: 500px) {
            margin-top: @margin;
            justify-content: space-between;
        }

        .btn {
            margin: 0;
            margin-top: @margin;
        }

        .btn-3 {
            margin: @margin * 0.5 0;
            flex-direction: row;

            .btn-label {
                margin-right: 0.5em;
            }
        }
    }
}

.collect__description {
    width: calc(~'100vw - @{margin}*2');
    position: relative;

    @media screen and (min-width: 800px) {
        width: 100%;
    }
}

button.validate-location {
    margin-left: 0;
    margin-top: @margin;
}

button.disabled {
    border-color: @greyLight;
    color: @greyLight;
}