.choice__list {
    margin-top: @margin;
}

.choice__wrapper {
    position: relative;
    border: 2px solid @activeColor;
    border-radius: (@minHeightLabel/2);
    margin-bottom: (@margin/2);
    color: @activeColor;

    details>summary {
        list-style: none;
    }

    details>summary::-webkit-details-marker {
        display: none;
    }

    summary {
        .absolute(0, initial, initial, (-@margin*3));
        border-radius: 100%;
        width: calc(~"@{minHeightLabel} - @{margin}");
        height: calc(~"@{minHeightLabel} - @{margin}");
        margin-top: (@margin/2);
        margin-right: (@margin/2);
        color: transparent; // hide the default arrow from SUMMARY html
        background-image: url('../../assets/picto-info.svg');
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
    }

    summary,
    .choice__label {
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
            transition: all 0.2s;

        }
    }

    details {
        padding: 0 @margin ;

        &[open] summary {
            // background-color: @textColor;
        }


    }


}

.choice__wrapper {

    &.selected {
        transition: all 0.4s;
        background-color: @activeColor;
        color: #fff;
    }

    &.unselected,
    &.unselectable {
        border-color: @greyLight;
        color: @greyLight;
    }
}

.choice__label {
    min-height: @minHeightLabel;
    display: flex;
    align-items: center;
    font-size: @choiceLabelSize;
    letter-spacing: -0.12rem;
    padding: @margin*0.75;
    border-radius: (@minHeightLabel/2);
}

.choice__wrapper {

    // .annotation__wrapper.answer,
    .annotate-answer {

        .btn {
            background-color: #fff;
            padding: 0 @margin*0.5;
            border-radius: 2rem;
            color: @activeColor;
            margin-left: @margin;
            width: fit-content;

            &:last-child {
                width: 32px; //fixME
            }
        }
    }

    .annotation__list {
        padding: 0 @margin;
    }

    .description {
        padding: 0 @margin;
        margin-top: -@margin;

        .md {
            padding-bottom: @margin * 0.5;

            p:last-child {
                margin-bottom: 0px;
                padding-bottom: (@margin / 2);
            }
        }
    }
}