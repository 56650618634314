// OBSERVATION

.main.observation {

    .question {
        margin: initial;
        margin-top: @margin * 3;
    }

    .obsevration-meta {
        margin-top: @margin;
        margin-bottom: @margin;
    }

    .question__infos {
        margin: @margin 0;

        .question {
            margin-bottom: @margin;
        }
    }

    .observation__meta {
        .inMargin();
        top: 0;
        bottom: initial;
    }

    .choice__list {
        margin-bottom: @margin;
    }

    .choice {
        position: relative;
        font-size: 1.5rem;
        display: flex;
        align-items: center;

        &.selected {
            color: @activeColor;
        }

        &.unselected {
            color: @inactiveColor;
        }

        .icon {
            width: 1.5em;
            flex-shrink: 0;
        }

        .choice__label {
            min-height: initial;
            padding: @margin * 0.2 0;
            width: calc(~"100% - 3.25rem - @{margin}");
            // margin-bottom: @margin * 0.5;
        }

        summary {
            .absolute(0, 0, initial, initial);
            .awsomefont();
            border-radius: 100%;
            width: 3.25rem;
            height: 3.25rem;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            transition: all 0.2s;
            // font-size: 1.25rem;
            // background-color: @bgLightColor;
            // color: @activeColor;
            cursor: pointer;

            background-image: url('../../assets/picto-info.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &:hover {
                transition: all 0.2s;
                border: 2px solid @activeColor;
            }
        }

    }

    .answers {
        margin-bottom: @margin;
    }

    .non-anwswered-questions {
        margin-top: @margin * 3;
    }
}

@media screen and (max-width: 900px) {

    .observation .observer,
    .observation .observed {
        max-width: 100%;
    }
}