.root.collect {

    .collect-map__wrapper,
    .collect__item {

        &:before,
        &:after {
            border-bottom: 2px solid @orange  !important;
        }
    }

    .question__number {
        border-top: 2px solid @orange  !important;
    }
}

.logo-print {
    background-image: url('../../assets/imgs/logo-smartwater-baseline.svg');
}



///

.community-log {
    margin-top: @margin * 2;
    margin-bottom: @margin;

    h2 {
        margin-bottom: @margin;
    }
}

.log {
    margin-bottom: @margin * 2;

    .log-meta {
        font-size: 0.8rem;
    }

    .log-meta,
    .log-part {
        margin-bottom: @margin;
    }
}

// .log-link {
//     text-decoration: underline;

//     .icon {
//         padding-left: 0.8em;
//     }
// }

.log-part {
    padding-left: @margin;

    .question {
        margin: @margin 0;
        font-weight: @fwSemiBold;
        padding-left: 2rem;
        font-size: 1rem;
    }



    .choice.selected {
        width: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        background-color: #eee;
        color: @activeColor;
        font-size: 1.1rem;
        margin-left: auto;
        margin-bottom: @margin;
    }

    .annotation__list {
        margin-top: @margin*0.5;
        display: flex;
        flex-direction: column;

        .meta {
            display: none;
        }

        .note {
            margin-left: auto;
            margin-bottom: @margin;
            width: fit-content;
        }

        .user-image {
            background-color: #eee;
            padding: @margin;
            border-radius: 2rem;
            margin-bottom: 0px;
        }

        .text .md {
            padding: 0.5rem 1rem;
            background-color: #eee;
            border-radius: 2rem;
        }
    }

    .label-question,
    .label-answer,
    .label-note {
        font-size: 0.8rem;
    }

    .choice.selected,
    .text .md,
    .note.image {
        position: relative;

        &:after {
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            background-image: url('../../assets/imgs/phil.svg');
            background-position: center top;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            bottom: -1.5rem;
            right: 2rem;

        }
    }

}

.log:nth-child(even) {


    .choice.selected,
    .note {
        // margin-left: 2rem;
        margin-left: initial;
    }

    .annotation__list {
        align-items: flex-start;
    }

    .choice.selected,
    .text .md,
    .user-image {

        &:after {
            background-image: url('../../assets/imgs/phil2.svg');
            right: initial;
            left: 2rem;

        }
    }
}