.ol-box {
    box-sizing: border-box;
    border-radius: 2px;
    border: 2px solid @inactiveColor;
}

.ol-overlay-container {
    will-change: left, right, top, bottom;
}

.ol-unsupported {
    display: none;
}

.ol-viewport,
.ol-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ol-selectable {
    -webkit-touch-callout: default;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.ol-overviewmap .ol-overviewmap-box:hover {
    cursor: move;
}