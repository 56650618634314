// Campaign details 
.question__infos {
    position: relative;
}

.question__list {
    padding-left: calc(~"@{smallSidebarMobile} + @{margin} + @{margin-mob}");
    position: relative;
    // margin-top: @margin * 4;
}

.question {
    position: relative;
    margin-top: @margin * 4;

    &:first-child {
        margin-top: 0px
    }
}


.question__number {
    .inMargin();
    top: 0;
    bottom: initial;
    font-size: 1rem;
    padding-top: @margin;
    border-top: 2px solid #000;
    color: @textColor;
}