details summary::-webkit-details-marker,
details summary::marker {
    display: none;
    content: '';
}

details summary {
    .focusVisible();
    margin-bottom: @margin;
}

details summary {
    display: flex;
    align-items: center;
    cursor: default;
}

details summary:after {
    .fa();
    font-size: 0.8em;
    display: block;
    content: @fa-var-chevron-right;
    margin-left: auto;
    padding-left: 1ch;
}

details[open] summary:after {
    content: @fa-var-chevron-down;
}

details summary.tooled {
    .summary-title {
        margin-right: auto;
    }

    &:after {
        margin-left: @margin;
    }
}

// Download Table

details.download-table {
    position: relative;

    summary {
        .fas();
        .focusVisible();
        margin: 0;
        // margin-right: (@margin/2);

        &.disabled:before {
            color: @disabledColor;
        }

        &::before,
        &::after {
            display: none
        }

        &.btn.icon-only {
            border-radius: initial;
        }
    }

    &[open] summary {
        color: @activeColor;
    }

    .download__body {
        .absolute(initial, initial, initial, 0);
        padding: (@margin/2);
        width: 200px;
        z-index: 2;
        background-color: white;
        border: 0 px;
        -webkit-box-shadow: 0px 6px 9px -4px @activeColor;
        -moz-box-shadow: 0px 6px 9px -4px @activeColor;
        box-shadow: 0px 6px 9px -4px @activeColor;
        border-bottom-left-radius: @borderRadius;
        border-bottom-right-radius: @borderRadius;
        font-size: 1rem;

        .export-title {
            font-weight: @fwSemiBold;
            margin-bottom: (@margin / 2);
        }

        .export-link {
            display: block;
            padding: (@margin/4) 0;
        }
    }
}