@blue2 : #009FD1;

@answerMaxWidth: 700px;
@answerItemWidth: 300px;
@minHeightLabel: 3.25rem;
@choiceLabelSize: 1.5rem;

.inMargin() {
    .absolute(initial, initial, initial, calc(~'-1 * (@{smallSidebarMobile} + @{margin})'));
    width: @smallSidebarMobile;
    color: #000;
    color: @black;
    font-size: 0.75rem;
}