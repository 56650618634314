// APP SELECT - Header

@appSelectPadding: @margin;

// .app-select__wrapper {
//     position: relative;

//     .tail {
//         position: absolute;
//         width: 100%;
//         max-height: 0px;
//         overflow: hidden;
//     }
// }

// .app-select {
//     cursor: default;

//     .selected {
//         display: flex;
//         justify-content: space-between;
//         padding: (@appSelectPadding / 2);
//     }
// }

// .app-select__wrapper.active {
//     background-color: white;
//     z-index: 20;

//     .tail {
//         max-height: 75vh;
//         width: 12em;
//         overflow: auto;
//     }
// }

///// STYLE
// .app-select .app-item {
//     display: block;
//     padding: 0.5em (@margin / 2);
//     justify-content: flex-start;

//     &:hover .app-name,
//     &:focus-visible .app-name {
//         text-decoration: underline;
//     }
// }

// .app-select .tail a {
//     text-decoration: none;
//     color: @textColor !important;
//     box-shadow: initial !important;
// }

// .app-select__wrapper.active {
//     .tail {
//         background-color: white;
//         padding: (@appSelectPadding / 2);
//         // border-bottom: @borderLight;
//         border: 0px;
//         -webkit-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
//         -moz-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
//         box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
//         border-bottom-left-radius: 5px;
//         border-bottom-right-radius: 5px;
//     }
// }

.app-select .app-item {
    .app-picto::before {
        .fa();
        font-family: 'ForkAwesome'; //FIXME
        font-weight: initial;
        content: @fa-var-check;
        margin-right: (@margin / 2);
    }

    &:hover .app-picto::before {
        color: @activeColor;
    }

    &.view .app-picto::before {
        content: @fa-var-map;
    }

    &.compose .app-picto::before {
        content: '\f040';
    }

    &.metadata .app-picto::before {
        content: '\f0f6';
    }

    &.statistics .app-picto::before {
        content: '\f201';
    }

    &.alias .app-picto::before {
        content: @fa-var-quote-right;
    }

    &.angled-project .app-picto::before {
        content: @fa-var-table;
    }

    &.angled-query .app-picto::before {
        content: @fa-var-search;
    }

    &.angled-universe .app-picto::before {
        content: @fa-var-book;
    }

    &.timeserie .app-picto::before {
        // content: @fa-var-chart-bar;
        content: '\eea2';
    }

    &.brugeotool .app-picto::before {
        // content: @fa-var-chart-bar;
        content: '\eea1';
    }

    &.infiltration .app-picto::before {
        // content: @fa-var-chart-bar;
        content: '\eea3';
    }

    &.solar .app-picto::before {
        // content: @fa-var-chart-bar;
        content: '\eea0';
    }
}
