.tag__list {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    position: relative;
    font-weight: @fwSemiBold;
    cursor: default;
    margin-right: (@margin / 2);
    margin-bottom: (@margin / 2);
    display: flex;
    align-items: center;
    background-color: @bodyBgColor;
    border-radius: 2em;
    min-height: 1.7rem;
    height: fit-content;
    width: fit-content;
    padding-right: 0.3rem;

    .tag__value {
        padding: 0 @margin;
    }

    button {
        height: 1.7rem !important;
        width: 1.7rem !important;
    }

    &.small {
        font-size: 0.8rem;
        min-height: initial;
        padding-right: 0px;

        .tag__value {
            padding: 0 @margin*0.5;
        }

        button {
            height: 1.3rem !important;
            width: 1.3rem !important;
        }
    }
}