.map-wrapper {
    background: url('../assets/imgs/map-trame.png') repeat;
    border-color: @borderLightColor;
    border-style: solid;
    overflow: hidden !important;

    .map {
        height: 100%;
        width: 100%;
        border-width: 1px;
        border-style: inherit;
        border-color: inherit;

        &:focus-visible {
            border-width: 2px;
            border-color: @activeColor;
        }
    }

    // .map-mode {
    //     .absolute(@small-margin, auto, auto, @small-margin);
    //     padding: @small-margin;
    //     min-width: 12px;
    //     min-height: 12px;
    //     background-color: @mapBgColor;
    // }


    .control-box {
        .absolute(initial, @margin*0.5, 25px, initial);
        width: 2rem;

        * {
            font-size: inherit !important;
        }

        >div button,
        >button {
            .fa();
            width: 2rem;
            height: 2rem;
            border: 2px solid @activeColor;
            border-radius: 2.5rem;
            background-color: @white;

            .icon {
                color: @textColor;
            }

            &:hover,
            &:focus-visible {
                color: @activeColor;
                border-color: @activeColor;
                background-color: @activeColor;
                outline: initial;

                .icon {
                    color: @white;
                }
            }
        }

        .btn--north,
        .zoom-in-btn,
        .zoom-out-btn,
        .btn-expand,
        .switch-background {
            margin-top: (@margin / 2);
        }
    }

    .credit {
        .absolute(initial, 0, 0, @small-margin) !important;
        font-size: 7pt;
        margin-bottom: 0px !important;
        width: calc(~'100% - @{small-margin}') !important;
        text-align: center;

        a {
            color: @greyDark;
            text-decoration: none;

            &:visited {
                color: @greyDark;
            }

            &:hover {
                color: @greyDark;
            }
        }
    }

    .map-scale {
        .absolute(initial, -1px, 0, initial);
        padding: @small-margin;
        padding-right: 0;
        padding-bottom: 0;
        background-color: @bodyBgColor;
        border-color: @bodyBgColor;
        border-width: 1px;
        border-style: inherit;
        border-top-color: inherit;
        border-left-color: inherit;
        display: flex;
        align-items: flex-end;

        .map-scale-label {
            font-size: 7pt;
            padding-right: @small-margin;
        }

        .map-scale-line {
            display: flex;
            align-items: flex-end;
            border: @borderDark;
            border-top: 0px;
            height: 6px;

            .quarter {
                flex: 1;
                height: 33%;

                &:first-child {
                    border-right: @borderDark;
                }
            }

            .half {
                flex: 2;
                height: 66%;
                border-left: @borderDark;
            }
        }
    }
}

// Hide map credit except in embed
.map .credit {
    display: none;
}

.embed .map .credit {
    display: block;
}