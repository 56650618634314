.main.login {

    button a {
        text-decoration: none;
    }

    .signup__wrapper {
        margin-top: @margin*2;
        margin-bottom: @margin*2;
    }

    .login-widget {
        margin-top: @margin;
        margin-bottom: @margin;

        .username {
            margin-top: @margin;
        }

        label {
            display: flex;
            flex-direction: column;
            margin-bottom: @margin*0.5;

            input {
                margin-top: @margin*0.3;
            }

        }

        .btn-1 {
            margin-top: @margin*0.5;
        }
    }

}