// Animated Icons
// --------------------------

.@{fa-css-prefix}-spin {
    animation: fa-spin 2s infinite linear;
}

.@{fa-css-prefix}-pulse {
    animation: fa-pulse 2s infinite ease-in-out;
}

@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fa-pulse {
    0% {
        transform: scale(0.75);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(0.75);
    }
}
