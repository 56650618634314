.root.smartwater {
    .absolute(0, 0, 0, 0);
    // overflow: hidden;

    >div {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        // overflow-y: auto;
    }

    .viewport {
        height: 100vh !important;
        overflow: auto;
    }

    h1 {
        width: calc(~"100vw - @{margin}*2");
        // margin-bottom: @margin*2.5;
    }

    .btn {
        width: fit-content;
    }

    @media screen and (max-width: 900px) {
        h1 {
            font-size: 2.7rem !important;
        }

        h2 {
            font-size: 1.4rem !important;
        }
    }

    h1,
    h2 {
        // word-wrap: break-word;
        // overflow-wrap: break-word;
        -ms-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    .header {
        border: 0px;
        margin-bottom: 0px;
        // margin-bottom: @margin*2;

        .app-listwrapper {
            display: none;
        }

        .header-toolbar {
            padding-bottom: @margin-mob * 2;
            border-bottom: @borderLight;

            .user {
                width: initial;
                margin-right: auto;
            }

        }

        // check if mobile ? 
        button:first-child {
            margin-left: 0px;
        }
    }

    .main {
        flex: 1;
        margin-top: auto;
        // min-height: calc(~"100vh - @{headerH} - @{margin}*6");
    }

    .breadcrumb {
        margin-bottom: @margin;
        // display: flex;// flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
    }

    .linked-collects {
        padding-bottom: @margin*1.5;
        border-bottom: @borderLight;
        margin-bottom: @margin*1.5;


        .collect-link__list {
            margin-top: @margin;
            display: flex;
            flex-direction: column;


        }

        .collect-link {
            margin-bottom: @margin*0.5;

            &:last-child {
                margin: 0px;
            }

            &.answered {
                order: 2;
                color: @inactiveColor;
                margin-top: @margin*0.5;
            }

            .icon {
                margin-right: 0.5em;
            }
        }
    }

    .icon {
        .fa();
        font-family: 'ForkAwesome';
    }

    .category {
        margin-right: @margin;
        margin-top: @margin*0.5;

        .icon {
            margin-right: @margin*0.5;
        }
    }

    .cat-box {
        .category-label {
            margin-bottom: @margin*0.5;
        }
    }

    .feedback {
        background-color: @textColor;
        color: #fff;
        padding: @margin;
        display: flex;
        justify-content: space-between;
        border-radius: 0.3em;
        margin-bottom: @margin;

        .btn {
            margin: 0px;

        }
    }



    .question-meta__wrapper,
    .collect-head__wrapper,
    .collect-title__wrapper,
    .question-meta__wrapper,
    .observation-head__wrapper {
        position: relative;
    }
}



// edit mode 

@media screen and (max-width: 900px) {

    .root.smartwater>div {
        height: initial;
        width: 100%;
        margin: 0px;

        &.header,
        &.footer {
            padding: 0 @margin * 0.5;
        }
    }

    .root.smartwater .main {
        overflow: initial;
        height: auto;

        &.home {
            margin-top: @margin;


        }

        >div {
            width: 100%;
            // max-width: 100vw;
            border-top: 0px;
        }
    }
}



.footer-actions {
    margin-top: @margin*1.5;
    border-top: @borderLight;
    padding-top: @margin*1.5;

    .btn {
        width: fit-content;
        margin-right: @margin * 0.5;
        margin-bottom: @margin * 0.5;

        &:last-child {
            margin-right: 0px;
        }
    }
}

// MINIMAP

.minimap__wrapper {
    // display: inline-block;
    position: relative;
    width: fit-content;
    height: fit-content;
    // margin: @margin 0;
    // border-radius: 1.5rem;
    overflow: hidden;

    img {
        width: 100%;
    }

    .minimap-message {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: @margin;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// MARKDOWN

.markdown img,
.md img {
    width: 100%;

    @media screen and (min-width: 900px) {
        max-width: 350px;
    }
}

.markdown h2,
.md h2 {
    border: 0px;
    color: @textColor;
}

/// BUTTON

// @media screen and (max-width : 500px) {

//     button.btn-1 {
//         width: 100%;
//     }
// }

// INPUT FILED

label input {
    width: 100%;
    max-width: 500px !important;
}