.footer {
    height: initial !important;
    top: initial;
    bottom: initial;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: @margin @margin*0.5;
    border-top: @borderLight;
    padding-top: @margin;

    .footer-infos>div {
        margin-bottom: @margin*0.5;
    }

    .fund-credit a {
        text-decoration: underline !important;
    }

    .credit.csac-credits {
        margin: initial;
    }
}

@media screen and (max-width: 900px) {}