.tabs__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
    z-index: 2;

    button.tab {
        background-color: @bgLightColor;
        height: 2em;
        padding: 0 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: @borderLight;
        // margin-right: @margin;
        margin-right: -1px;
        border-top-left-radius: @margin *0.5;
        border-top-right-radius: @margin *0.5;
        font-weight: @fwSemiBold;
        font-size: 1em;
        color: @inactiveColor;
        border-bottom: @borderLight;

        &:first-child {
            margin-left: @margin*0.5;
        }

        &:hover {
            background-color: @bodyBgColor;
            color: @textColor;
            border-bottom: @borderLight;
        }


        &.selected {
            background-color: @bodyBgColor;
            color: @textColor;
            border-bottom: 1px solid @bodyBgColor;
            // border-bottom: 1px solid yellow;
            // margin-bottom: -1px;

            &:hover {
                border-bottom: 1px solid @bodyBgColor;
            }
        }


    }
}

.tab__content {
    padding-top: @margin;
    border-top: @borderLight;
}