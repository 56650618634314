.focusVisible() {
    &:focus {
        outline: 2px solid @blue;
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        outline: 2px solid @blue;
    }
}
.focusWithin() {
    &:focus-within {
        outline: 2px solid @blue;
    }
    *:focus,
    *:focus-visible {
        outline: none;
    }
}
