@selectFilterHeight : 2rem;

.select-filter__wrapper {
    .focusVisible();
    background-color: @bodyBgColor;
    border: none;
    border-bottom: 1px solid @textColor;
    width: 100%;
    min-width: 12ch;
    max-width: 50ch;
    min-height: @selectFilterHeight;
    margin-top: 0.3em;
    font-size: 1rem;
    cursor: default;
    position: relative;
    z-index: 3;




    summary {
        padding: (@margin / 2);
        margin-bottom: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        // font-size: 0.8rem;

    }

    .select-tail__wrapper {
        position: absolute;
        // top : @selectFilterHeight ;
        max-height: 12rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: (@margin / 2);
        background-color: white;
        padding: (@appSelectPadding / 2);
        -webkit-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
        -moz-box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
        box-shadow: 0px 6px 9px -4px rgba(153, 153, 153, 1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .input__wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            input {
                flex-shrink: 1;
                margin-right: (@margin/2);
            }

            .btn-add {
                margin-left: auto;
                flex-shrink: 0;
            }
        }


        input {
            margin-bottom: (@margin / 2);
            flex-shrink: 0;
        }

        .tail {
            overflow-y: auto;
            overflow-x: hidden;

            button {
                display: flex;
                width: 100%;
                font-size: inherit;

                .item__control {
                    display: block;
                    width: 1em;
                    height: 1em;
                    border-radius: 1em;
                    margin-right: 0.5em;
                    border: 2px solid @disabledColor;
                    flex-shrink: 0;
                }

                &:focus-visible .item__control,
                &:hover .item__control {
                    border: 2px solid @activeColor;
                    background-color: @activeColor;
                    box-shadow: 0px 0px 0px 3px @bodyBgColor inset;
                }

                .item__label div {
                    text-align: left;
                }
            }
        }
    }
}

.table-search-item .select-filter__wrapper * {
    font-size: 0.8rem;
}