.radio__fieldset {
    .radio__item {
        margin-bottom: (@margin / 2);

        &:last-child {
            margin: 0px;
        }

        &:focus-within {
            .radio__control-inner {
                // box-shadow: 0px 0px 0px 1px @white, 0px 0px 0px 3px @activeColor !important ;
                background-color: @activeColor  !important;
            }
        }
    }

    label {
        display: flex;
        align-items: center;
    }

    .radio__control {
        position: relative;
        flex-shrink: 0;
        width: 1.25em;
        height: 1.25em;
        border-radius: 1em;
        margin-right: 0.5em;
        border: 2px solid @activeColor;
        background-color: @white;

        &-inner {
            .absolute(2px, 2px, 2px, 2px);
            border-radius: 1em;
            background-color: transparent;
        }

        &:focus {
            outline: none;
        }
    }

    .radio__item:hover .radio__control-inner {
        background-color: @activeColor;
    }

    input {
        // position: absolute;
        opacity: 0;
        width: 0px;
        height: 0px;
        cursor: pointer;

        &:checked+.radio__control>span {
            background-color: @activeColor;
        }

        &:focus+.radio__control {
            // outline: 2px solid @textColor; need more assessment - pm
            outline: none;
        }
    }
}

.radio__fieldset.fieldset--horizontal {
    .radio__item {
        margin-bottom: 0px;

        &:last-child .radio__control {
            margin: 0px
        }
    }
}

.switch-out__fieldset {
    position: relative;
    display: flex;
    align-items: center;

    .switch__body {
        display: flex;
        align-items: center;
    }

    .toggle__wrapper {
        box-sizing: content-box;
        flex-shrink: 0;
        border: 2px solid @activeColor;
        border-radius: 2em;
        display: flex;
        background-color: @bgLightColor;
        width: 2em;
        height: 1em;
        margin: 0 0.5em;

        &.first-selected .toggle__inner {
            margin: 0;
            margin-right: auto;
        }

        &.second-selected .toggle__inner {
            margin: 0;
            margin-left: auto;
        }

        .toggle__inner {
            box-sizing: border-box;
            width: 1em;
            height: 1em;
            border-radius: 1em;
            background-color: @activeColor;
            border: 1px solid @bgLightColor;
        }
    }

    .switch__item {
        .focusWithin();
    }

    label {
        input[type='radio'] {
            // .focusVisible();
            position: absolute;
            left: 0;
            opacity: 0;
            z-index: -1;
        }
    }
}

.switch__fieldset {
    display: flex;

    .switch__body {
        position: relative;
        display: flex;
        align-items: center;
        border: 2px solid @greyDark;
        border-radius: 2em;
        background-color: @bgLightColor;

        &:focus-within {
            outline: 3px dotted @activeColor;
        }

        >div {
            padding: (@margin / 2) @margin;
            border-radius: 2em;
            border: 2px solid @bgLightColor;

            &.selected {
                // background-color: @activeColor;
                background-color: @activeColor;
                color: @bodyBgColor;
            }

            &.unselected {
                // color: @inactiveColor;
                color: @textColor;
            }

            &:focus-within,
            &:hover {
                color: @activeColor;
                background-color: @bodyBgColor;
                box-shadow: inset 0 0 0 2px @activeColor;

                &.selected {
                    background-color: @activeColor;
                    color: @white;
                }
            }
        }

        .switch__item {
            text-align: center;
        }
    }

    label {
        input[type='radio'] {
            position: absolute;
            left: 0;
            opacity: 0;
        }
    }
}