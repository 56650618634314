//POSITIONS
.header {
    .absolute(0, 0, initial, 0);
    height: @headerH;
}

.main {
    .absolute(@headerH, 0, @footerH, @margin); //0  to keep the scroll-bar on the side
    padding-right: @margin;
    overflow-x: hidden;

    >div {
        height: 100%;
    }
}

.footer {
    .absolute(initial, 0, 0, 0);
    height: @footerH;
}

.sidebar-right {
    //overflow is managed in wgt-legend.less
    .absolute(0, 0, 0, calc(~"100% - @{panel-rightW}"));
}

.map-fs,
.map-and-info {
    .map-wrapper {
        .absolute(0, @panel-rightW, 0, 0);
    }
}


.layer-stub {
    .inspire-wrapper {
        .absolute(0, 0, 0, calc(~"100% - @{panel-rightW} + @{margin} - 1px"));
        height: 100%;
        z-index: 3;
    }
}

//////////   MAIN / MAP & RIGHT SIDEBAR

.map-and-right-sidebar,
.main-and-right-sidebar {
    >div:nth-child(1) {
        .absolute(0, @panel-rightW, 0, @margin);
    }

    >div:nth-child(2) {
        .absolute(0, @margin, 0, calc(~"100% - @{panel-rightW} + @{margin} - 1px"));
    }
}

//////////   VERTICAL SPLIT & SNAIL SYSTEM

.vertical-split {
    .absolute(0, @panel-rightW, 0, 0);
    display: flex;
    flex-direction: column;

    >div {
        flex: 1;
        position: relative;
        overflow: auto;
        overflow-x: hidden;


        &:first-child {
            margin-bottom: @margin;
        }


        &.snail {
            display: flex;
            flex-direction: row-reverse;

            >div {
                flex: 1;
                position: relative;
                overflow: auto;

                &.map-wrapper {
                    overflow: hidden;
                    margin-left: @margin;
                }

                >div:nth-child(2) {
                    direction: rtl; // scrool bar trick

                    &>* {
                        direction: ltr;
                    }
                }
            }
        }
    }
}


////////// SPLITTED WIDGET APPLICATIONS  / REGIONS STRUCTURE

.app-split-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    // height: @headerH;
    padding: @small-margin;
    padding-right: 0px;
    border-top: @borderLight;
    border-bottom: @borderLight;

    .app-split-title {
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        .icon {
            margin-right: 0.5em;
        }

    }

    .btn {
        margin-left: @margin*0.5;
    }

    .legend-type {
        text-transform: lowercase;
    }
}

.app-split-main {
    display: flex;
    justify-content: space-between;
    height: calc(~"100% - @{headerH}");
}