// from : https://elazizi.com/a-step-by-step-guide-to-making-pure-css-tooltips

@tooltip-font-size: 0.8rem;
@tooltip-opacity: 0.9;
@tooltip-bg-color: rgb(75, 75, 75);
@tfs: @tooltip-font-size;

// TOOLTIP

[data-tooltip] {
    position: relative;
    display: inline-block;
}

[data-tooltip].icon {
    .fa();
    cursor: default;

    &:hover {
        color: @activeColor;
    }
}

[data-tooltip]:hover::after,
[data-tooltip]:hover::before {
    opacity: @tooltip-opacity;
    transition-delay: 0.5s;
}

[data-tooltip]:focus-visible::after,
[data-tooltip]:focus-visible::before {
    opacity: @tooltip-opacity;
    transition-delay: 0s;
}

// CONTENT

[data-tooltip]::after {
    content: attr(data-tooltip);
    font-family: @gui-font;
    font-size: @tfs;
    font-weight: 600;
    letter-spacing: 0.03em;
    text-transform: initial;
    line-height: initial;
    position: absolute;
    left: 50%;
    top: (-@tfs / 2);
    transform: translateX(-50%) translateY(-100%);
    background: @tooltip-bg-color;
    text-align: center;
    color: @white;
    min-width: 100px;
    border-radius: 5px;
    // border: 1px solid @blue;
    pointer-events: none;
    padding: (@tfs / 3);
    z-index: 9999999;
    opacity: 0;
}

// CONTENT POSITIONNING

[data-tooltip-position='left']::after {
    left: 0%;
    top: 50%;
    margin-left: (-@tooltip-font-size / 3 * 2);
    transform: translateX(-100%) translateY(-50%);
}

[data-tooltip-position='top']::after {
    left: 50%;
}

[data-tooltip-position='bottom']::after {
    top: 100%;
    margin-top: (@tooltip-font-size / 3 * 2);
    transform: translateX(-50%) translateY(0%);
}

[data-tooltip-position='right']::after {
    left: 100%;
    top: 50%;
    margin-left: (@tooltip-font-size / 3 * 2);
    transform: translateX(0%) translateY(-50%);
}

[data-tooltip-position='top-right']::after {
    margin-left: -15px;
    transform: translateX(0%) translateY(-100%);
}

[data-tooltip-position='top-left']::after {
    margin-left: 15px;
    transform: translateX(-100%) translateY(-100%);
}

[data-tooltip-position='bottom-right']::after {
    top: 100%;
    margin-left: (-@tooltip-font-size / 3 * 4);
    margin-top: (@tooltip-font-size / 3 * 2);
    transform: translateX(0%) translateY(0%);
}

[data-tooltip-position='bottom-left']::after {
    top: 100%;
    margin-left: (@tooltip-font-size / 3 * 4);
    margin-top: (@tooltip-font-size / 3 * 2);
    transform: translateX(-100%) translateY(0%);
}