// TO DO
// Check if .activeText() mixin shouldn't be merged into .list 
// and removed from general text mixins

.list {

    &__item {
        .textBig();
        .activeText();
        font-weight: @fwSemiBold;

        &--drawer {
            &.selected {
                background-color: @bgLightColor;
            }

            &:hover:after,
            &.selected:after {
                content: @fa-var-caret-right;
            }
        }
    }
}