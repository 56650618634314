.main.collect {

    .collect-meta__wrapper,
    .collect-map__body {
        position: relative;
    }

    .collect-map__side {
        top: 0;
    }

    .question-lists__wrapper {
        margin-top: @margin *4;
    }

    .collect-map__wrapper {
        position: relative;
        margin-bottom: @margin;

        h2 {
            margin-bottom: @margin;
        }


        .collect__meta {
            margin-bottom: @margin;
        }
    }

    .collect-map__body {
        .legend {
            .legend-item {
                margin-top: @margin*0.3;
            }

            .legend-icon {
                margin-right: 1ch;

                &.week {
                    color: #ff6684;
                }

                &.month {
                    color: #ff6684bf;
                }

                &.more-than-month {
                    color: #ff668480;
                }
            }

            .legend-label {
                font-weight: initial;
            }
        }
    }

    .question-lists__wrapper {

        .choice__wrapper {
            border-color: @greyMedium  !important;
        }

        .choice__label {
            &:hover {
                background-color: initial;
                color: @greyMedium;
            }

            color: @greyMedium;
            font-size: 1.5rem;
            min-height: 2.25rem;
        }
    }

    .add-observation__wrapper {
        margin-top: @margin * 2;
    }

}