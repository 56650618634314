.root.embed-log.smartwater {
  h1 {
    font-size: 2.2rem !important;
    margin-bottom: @margin;
  }

  .main {
    padding: @margin;
    border: 2px solid @activeColor;
    border-radius: 2rem;
    margin: auto;
    max-height: 0.75vh;
    min-height: 500px;

    .community__wrapper {
      height: 100%;
      margin-bottom: @margin *2;
      overflow: hidden;
    }

    .log-list {
      height: 100%;
      overflow: auto;
      padding-right: @margin ;
    }
  }
}


.log .annotation__list {
  .note.image {
    img {
      max-width: 300px;
      margin-top: @margin;
    }
  }

  .note {
    audio {
      max-width: 300px;
    }
  }
}