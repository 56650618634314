div.infinite-table {
    height: 100%;
    // min-height: 100%;

    .widgetBackground;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .attr-headless-wrapper {
        .absolute(0, 0, 0, 0);
    }

    &.loading {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .table-toolbar {
        flex: 0 0 auto;
        min-height: 25px;
        padding: @small-margin;
        padding-right: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: @borderLight;

        .table-title {
            font-size: 1.4rem;
            max-width: 50%;

            &::first-letter {
                text-transform: capitalize;
            }

            &:before {
                .fa();
                content: @fa-var-table;
                display: inline-block;
                margin-right: @small-margin;
            }
        }

        .table-download {
            >span {
                .link;
                margin-right: @margin;

                &:before {
                    .fa();
                    content: @fa-var-download;
                    display: inline-block;
                    margin-right: @small-margin;
                }
            }
        }
    }

    .table-row,
    .table-header {
        width: 100%;
        border-bottom: @borderLight;
        cursor: default;
        display: flex;

        .table-cell {
            // display: inline-block;
            padding: @small-margin;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-shrink: 0;

            &:last-child {
                border-right: none;
            }

            &:first-child {
                text-align: right;
                min-width: 50px;
            }

            &.data-type-number {
                text-align: right;
            }

            &.table-header-cell {
                display: inline-flex;
                align-items: center;

                .btn {
                    color: @disabledColor;
                    // border: 0px !important;
                }

                &:hover {
                    .btn {
                        color: @inactiveColor;

                        &:hover {
                            color: @activeColor;
                        }
                    }
                }

                .adjust-col {
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    margin-right: (@margin / 2);

                    * {
                        font-size: 0.6rem !important;
                    }

                    .btn {
                        border-radius: 100%;

                        &:hover,
                        &:focus-visible {
                            background-color: @activeColor;

                            .icon {
                                color: @white  !important;
                            }
                        }
                    }
                }

                .btn-add {
                    width: 1rem;
                    height: 1rem;
                }
            }

            &.table-header-cell .label-col {
                flex-shrink: 1;
                margin-right: auto;
                display: flex;
                overflow: hidden;

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 150%;

                    &:hover {
                        color: @activeColor;
                    }
                }

                span:before {
                    .fa();
                    content: @fa-var-angle-up;
                    padding-right: @small-margin;
                    color: @disabledColor;
                    font-size: 0.8rem !important;
                }

                span:hover:before {
                    color: @activeColor;
                }
            }

            &.sorted {
                color: @activeColor;

                &.sorted-asc .label-col span:before {
                    .fa();
                    content: @fa-var-angle-up;
                    color: @activeColor;
                }

                &.sorted-desc .label-col span:before {
                    .fa();
                    content: @fa-var-angle-down;
                    color: @activeColor;
                }
            }

            .btn-search {
                margin-left: @small-margin;
            }
        }
    }

    .table-search {
        flex: 0 0 auto;
        .flex(flex-start, flex-start, wrap);
        padding-top: @small-margin;
        border-top: 1px solid @borderDarkColor;

        .filter--wrapper {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
        }

        input {
            .textExtraSmall();
        }

        .table-search-item {
            margin-bottom: (@margin / 2);
            margin-right: @margin;

            .search-field {

                margin-right: 1ch;
                font-weight: @fwSemiBold;
            }

            .filter-op__wrapper {
                display: flex;
                align-items: center;
                margin-top: @small-margin;
            }

            .filter-op__label {
                .textExtraSmall();
            }

            .filter-op {
                width: 1.5em;
                height: 1.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: (@margin / 2);
                font-weight: @fwSemiBold;
                border-radius: 100%;

                &:hover,
                &.selected {
                    background-color: @bgLightColor - 20%;
                }
            }
        }
    }

    .table-main {
        overflow-x: auto;
        overflow-y: auto;

        // display: flex;
        // flex-direction: column;
        flex: 1;

        .table-header {
            // flex: 0 0 auto;
            display: flex;
            position: sticky;
            top: 0;
            background-color: @bgLightColor;
            z-index: 2;

            font-weight: @fwBold;
            border-top: @borderLight;
            min-width: 100%;

            .table-header-cell {
                overflow: initial;
                border-right: @borderLight;
                margin: @small-margin 0;
                padding: 0 @small-margin;

                &:last-child {
                    border: 0px;
                }
            }
        }

        .table-body {
            // overflow-y: auto;
            // overflow-x: hidden;
            // min-width: 100%;
        }

        .table-row {
            .transition;

            &.active,
            &.active:hover {
                background-color: @tableRowActiveColor;
            }

            &:hover {
                background-color: @tableRowHoverColor;
            }
        }
    }

    .btn-next {
        margin: 0 @small-margin;
    }

    .table-body {

        ::-moz-selection,
        ::selection {
            background: @tableTextSelectColor;
        }
    }
}