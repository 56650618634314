select {
    .focusVisible();
    background-color: @bodyBgColor;
    border: none;
    border-bottom: 1px solid @textColor;
    padding: (@margin / 2) @margin;
    margin: 0;
    width: 100%;
    font-family: @gui-font;
    font-size: 1rem;
    cursor: default;
    line-height: inherit;

    option {
        background-color: @bodyBgColor;
    }
}

