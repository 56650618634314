.modal__background {
    font-size: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10000000000;
    background: rgba(0, 0, 0, 0.2);

    .modal__box {
        position: relative;
        background: @white;

        &.modal-size-large {
            width: 66vw;
            height: 100vh;
        }

        &.modal-size-medium {
            width: 50vw;
            min-height: 66vh;
        }

        &.modal-size-small {
            width: 32vw;
            min-height: 16vw;
        }
    }
}

.modal__box {
    animation: slide 0.3s;
}

@keyframes slide {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

// INNER POSITIONNING

.modal {
    &__inner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__header {
        flex-shrink: 0;
    }

    &__content {
        flex: 1;
        overflow: auto;
    }

    &__footer {
        flex-shrink: 0;
    }
}

// INNER STRUCTURE

.modal {
    &__header {
        padding: @margin;
        border-bottom: @borderLight;
    }

    &__content {
        padding: @margin;
    }

    &__footer {
        padding: @margin;
        border-top: @borderLight;

        &__inner {
            display: flex;
            justify-content: space-between;
        }
    }
}

// INNER STYLE

.modal__box {
    &__header {
        h2 {
            margin: 0;
        }
    }

    .modal__content {
        h2 {
            color: @inactiveColor;
        }

        &--item--list {}

        &--item {
            margin-bottom: @margin * 2;

            // input,
            textarea {
                margin-right: @margin;
            }

        }
    }
}