@btnFontSize: 0.8rem;
@innerPadding: @btnFontSize / 2;
@borderRadius: @margin;

// RESET BUTTON BASE STYLE

button {
    .focusVisible();
    border: 0px;
    background-color: transparent;
    font: 400 @btnFontSize @gui-font;
    padding: 0px;
}

// STRUCTURE

.btn {
    min-height: calc(~'1em + @{innerPadding}');
    text-align: left;
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0.4em 1em;
    box-sizing: border-box;
    flex-shrink: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;

    &.label-and-icon .icon {
        padding-right: 0.8em;
        padding-top: 0.2em;
    }

    &.icon-only {
        width: calc(~'2em + @{innerPadding}');
        padding: 0;

        .icon {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.btn.btn-3 {
    padding: 0;
}

// STYLE GENERAL

.label-and-icon,
.icon-only {
    .icon {
        .awsomefont;
    }
}

.btn {
    .transition;
    cursor: default;
    letter-spacing: 0.1ch;
    text-transform: uppercase;
    font-weight: 400;
    font-size: @btnFontSize;
    border-radius: @borderRadius;

    * {
        .transition;
    }
}

@btnInactive: @greyLight;

// STYLE LEVEL 1 - Big Buttons

@btn1Color: #fff;
@btn1InnerColor: @activeColor;
@btn1BorderColor: @btn1InnerColor;

@btn1ColorHover: @btn1Color;
@btn1InnerColorHover: @btn1InnerColor - 30%;
@btn1BorderColorHover: @btn1InnerColorHover - 30%;

@btn1ColorFocus: @btn1Color;
@btn1InnerColorFocus: @btn1InnerColor - 60%;
@btn1BorderColorFocus: @btn1InnerColorFocus;

.btn-1 {
    background-color: @btn1InnerColor;
    color: @btn1Color;
    border: 1px solid @btn1BorderColor;

    &:hover {
        color: @btn1ColorHover;
        background-color: @btn1InnerColorHover;
        border-color: @btn1BorderColorHover;
    }

    &:focus,
    &:active {
        color: @btn1ColorFocus;
        background-color: @btn1InnerColorFocus;
        border-color: @btn1BorderColorFocus;
    }

    &.inactive,
    &.disabled {
        color: @btn1Color;
        background-color: @greyLight;
        border-color: @greyLight;
    }

    &.icon-only {
        border: 0px;
        border-radius: 100%;
    }
}

// STYLE LEVEL 2 - Average buttons
@btn2Color: @textColor;
@btn2InnerColor: @white;
@btn2BorderColor: @textColor;

@btn2ColorHover: @activeColor;
@btn2InnerColorHover: @btn2InnerColor;
@btn2BorderColorHover: @activeColor;

@btn2ColorFocus: @activeColor;
@btn2InnerColorFocus: @btn2InnerColor;
@btn2BorderColorFocus: @btn2InnerColorFocus;

.btn-2 {
    background-color: @btn2InnerColor;
    border: 1px solid @btn2BorderColor;
    color: @btn2Color;

    &:hover {
        color: @btn2ColorHover;
        background-color: @btn2InnerColorHover;
        border-color: @btn2BorderColorHover;
    }

    &:focus,
    &:active {
        color: @btn2ColorFocus;
        background-color: @btn2InnerColorFocus;
        border-color: @btn2BorderColorFocus;
    }

    &.inactive,
    &.disabled {
        color: @btnInactive;
        background-color: transparent;
        border-color: transparent;
    }

    &.icon-only {
        border: 0px;
        border-radius: 100%;
    }

    .icon {
        color: @activeColor;
    }
}

// STYLE LEVEL 3 - DENSE INTERFACE

@btn3Color: @textColor;
@btn3InnerColor: transparent;
@btn3BorderColor: transparent;

@btn3ColorHover: @activeColor;
@btn3InnerColorHover: transparent;
@btn3BorderColorHover: transparent;

@btn3ColorFocus: @activeColor + 30%;
@btn3InnerColorFocus: transparent;
@btn3BorderColorFocus: transparent;

.btn-3 {
    background-color: @btn3InnerColor;
    border: 1px solid @btn3BorderColor;
    color: @btn3Color;

    &:hover {
        color: @btn3ColorHover;
        background-color: @btn3InnerColorHover;
        border-color: @btn3BorderColorHover;
        // border-bottom-color: @btn3ColorHover;
    }

    &:focus,
    &:active {
        color: @btn3ColorFocus;
        background-color: @btn3InnerColorFocus;
        border-color: @btn3BorderColorFocus;
        // border-bottom-color: @btn3ColorFocus;
    }

    &.inactive,
    &.disabled {
        color: @btnInactive;
        background-color: transparent;
        border-color: transparent;
    }

    &.icon-only {
        border: 0px;
        border-radius: 100%;

        &:hover,
        &:focus,
        &:active {
            background-color: @btn2InnerColor;
        }
    }

    .icon {
        color: @activeColor;
    }
}

// TRANSLATE/D BUTTON
.btn-translate {
    font-size: 0.5rem !important;
    color: @greyDark;
    margin: 0 !important; //reset
    margin-right: -1em !important;
    margin-top: -1em !important;

    &.not-translated {
        color: @activeColor;
    }
}

// REMOVE - CONFIRMATION WIDGET

.btn.btn-remove {
    // font-size: 0.65rem;
    // font-weight: 400;

    &:hover {
        border: 1px solid @yellow;
        background: @yellow;
        color: @activeColor;
    }

    &:active {}
}

.remove-confirm {
    font-size: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: @white;
    padding: @margin / 2;
    background: rgba(255, 255, 255, 0.2);

    .remove-confirm-box {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        -moz-box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        box-shadow: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);
        background: @white;
        width: 33vw;
        height: 33vh;
        min-width: 350px;
        min-height: 250px;
        padding: @margin * 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .remove-confirm-btns {
            display: flex;
            justify-content: space-around;
        }
    }
}

// NOTES FOR ANIMATIONS
// button transition :
// :active trigger event on click PRESS
// transition trigger event on clic RELEASE