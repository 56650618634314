@fontFamilyName1: 'fuga';
@fontFamilyDir1: '../assets/fonts/fuga';
@fontFamilyPathBaseName1: 'fuga';


// REGULAR - 400 - normal

@font-face {
    font-family: '@{fontFamilyName1}';
    src: url('@{fontFamilyDir1}/@{fontFamilyPathBaseName1}.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}