// Annotation form

.main.annotation-form {
    width: calc(~"100% - @{margin-mob}*4");
    // padding: 0 @margin;


    * {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .answer__wrapper {
        border: 2px solid @greyMedium;
        border-radius: 2em;
        padding: @margin;
        margin-bottom: @margin;
        background-color: #eee;
    }

    .choice__list {
        margin-bottom: @margin;
    }

    .choice {
        position: relative;
        font-size: 1.5rem;
        display: flex;
        align-items: center;

        &.selected {
            color: @activeColor;
        }

        &.unselected {
            color: @inactiveColor;
        }

        .icon {
            width: 1.5em;
            flex-shrink: 0;
        }

        .choice__label {
            min-height: initial;
            padding: @margin * 0.2 0;
            width: calc(~"100% - 3.25rem - @{margin}");
            // margin-bottom: @margin * 0.5;
        }
    }

    .footer-actions {
        display: flex;
        justify-content: space-between;
        border-top: 0px;
        margin-top: 0px;
    }
}


// IMAGE FORM

.image-form {

    .target,
    .upload-image>* {
        margin-top: @margin;
    }

    img {
        display: block;
        width: 100%;
        max-width: 300px;

        &.selected {
            opacity: 0.5;
        }
    }

    input {
        display: block;
    }
}

// TEXT FORM



.text-form {
    .target {
        margin-top: @margin;
    }

    textarea {
        display: block;
        width: 100%;
        margin-top: @margin;
        font-size: 1.25rem;
        background-color: lighten(@activeColor, 47%);
    }
}