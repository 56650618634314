.tabs__list {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: @margin;
    border-bottom: 1px solid @textColor;

    button {
        width: calc(~"50% - @{margin}") !important;
        border-radius: @margin  !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom: 0px !important;
        padding: @margin *1.2 !important;
        margin-left: 0px !important;


        &.unselected {}

        &.selected {
            color: @white  !important;
            background-color: @activeColor  !important;
            background-color: @textColor  !important;
            font-weight: initial;
        }
    }

}

details.collect__category {
    margin-top: @margin;

    .collect__list {
        padding: @margin*0.5;
        // border-left: 2px solid @textColor;
        background-color: @textColor;
        border-bottom-left-radius: @margin *1.5 !important;
        border-bottom-right-radius: @margin*1.5  !important;

        .collect__item:first-child {
            margin-top: 0px;
        }

        .collect__item:last-child {
            margin-bottom: 0px;
        }

        .collect__item {
            background-color: @bodyBgColor;
        }
    }

    &[open] {
        summary {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
    }

    summary.category-summary {
        position: relative;
        // width: fit-content;
        margin-bottom: 0px;
        color: @white;
        background-color: @textColor;
        padding: 0.5rem 1rem;
        border-radius: @margin;
        display: flex;
        flex-direction: column;
        align-items: baseline;

        &:after {
            position: absolute;
            right: @margin ;
        }

        .cat-title {
            text-transform: uppercase;
            letter-spacing: 0.1ch;
            margin-bottom: @margin *0.5;

            .icon {
                font-size: 0.7em;
                margin-right: 1ch;
            }
        }

        .cat-description {
            margin: @margin * 0.5 0px
        }
    }
}