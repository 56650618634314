.map-list {

    .map-tile {
        position: relative;
        flex-basis: 18%;
        min-width: 140px;
        max-width: 180px;
        font: inherit;
        text-align: left;
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus-visible {
            outline: none;

            h3 {
                text-decoration: underline;
            }
        }

        h3.map-title {
            line-height: 0.9;
            margin-top: @margin;
            margin-bottom: @margin*0.5;
            color: @textColor;
        }

        .map-status {
            position: absolute;
            top: 0;
            font-size: 0.7rem;
            border: @borderDark;
            border-color: @activeColor;
            background-color: @bodyBgColor;
            padding: 0.3em 0.5em;
            border-radius: 2em;
            width: fit-content;
            z-index: 2;

            .icon {
                margin-right: 0.5em;
            }

            &.draft .icon {
                color: @greyDark;
            }

            &.published .icon {
                color: @blue;
            }

            &.public .icon {
                color: @green;
            }
        }

        .map-tile-img {
            background-color: @bgLightColor;
            position: relative;
            width: 90%;
            margin: @margin*0.5 auto;
            overflow: hidden;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            img,
            .no-image {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 100%;

                &:not([src]) {
                    width: calc(~'100% + 10px');
                    height: calc(~'100% + 10px');
                    top: -5px;
                    left: -5px;
                    bottom: -5px;
                    right: -5px;
                }
            }

            .no-image {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    color: darken(@bgLightColor, 5%);
                    font-size: 4rem;
                }
            }
        }
    }
}