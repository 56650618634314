// Annotations

.annotate-trigger {
    .inMargin();
    width: initial;

    .annotation-number {
        width: (@smallSidebarMobile/1.75);
        // margin-top: -@margin;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 1rem;
        color: @textColor;

        &:after {
            content: '';
            flex-shrink: 0;
            width: 1.5em;
            height: 1.3em;
            display: block;
            background-image: url('../../assets/picto-comment.svg');
            background-repeat: no-repeat;
            background-size: contain;

        }
    }

    // .annotation-label {
    //     font-size: 0.8rem;
    // }
}

.question__infos .annotate-trigger {
    top: 75%;
}

.choice__wrapper .annotate-trigger {
    height: 100%;
    top: @margin
}