* {
    .scroll-mixin();
}

body {
    font-family: @gui-font;
    font-size: @gui-font-size;
    background-color: @bodyBgColor;
    color: @textColor;
}

h1 {
    font-weight: @fwExtraBold;
    font-size: 330%;
    line-height: 90%;
    letter-spacing: -1.5px;
    min-height: 1em;
}

h2 {
    .textBig();
}

h3 {
    font-weight: @fwBold;
}

a {
    .transition;
    color: @linkColor;
    text-decoration-color: @linkDecorationColor;

    &:hover {
        color: @linkColorHover;
    }
}

.loader-spinner {
    // .awsomefont-spin;
    .fa();
    .fa-spin();
    margin: 0 @small-margin;

    &:before {
        color: @loaderColor;
        content: @fa-var-circle-notch;
    }
}

.toggle {
    .flex(@justify: flex-start);

    .active,
    .no-active {
        font-weight: 500;
    }

    .active {
        color: @activeColor;
    }

    .no-active {
        color: @inactiveColor;
    }

    .icon-only:before {
        font-size: 18px;
        color: @activeColor;
        margin: 0 @small-margin;
    }
}

.hidden {
    display: none !important;
}
