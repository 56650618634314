.map-wrapper .control-box {
    bottom: (@margin / 2);
    right: (@margin / 2);
    width: 2rem;
    font-size: 1rem;
    padding-top: 0.5rem;
    background-color: transparent;
    border: 0px;
    * {
        font-size: inherit !important;
    }
    > div button,
    > button {
        width: 2rem;
        height: 2rem;
        border: 2px solid @activeColor;
        border-radius: 2.5rem;
        background-color: @white;

        .icon {
            color: @activeColor;
        }

        &:hover,
        &:focus-visible {
            border-color: @activeColor;
            background-color: @activeColor;
            outline: initial;

            .icon {
                color: @white;
            }
        }
    }

    .btn-expand {
        margin-top: (@margin / 2);
    }
}