@fontFamilyName2: 'jetbrainsMono';
@fontFamilyDir2: '../assets/fonts/jetbrains';
@fontFamilyPathBaseName2: 'JetBrainsMono';

// BLACK - 900

// @font-face {
//     font-family: '@{fontFamilyName2}';
//     src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-black.woff2') format('woff2');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: '@{fontFamilyName2}';
//     src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-blackitalic.woff2') format('woff2');
//     font-weight: 900;
//     font-style: italic;
// }

// EXTRA-BOLD -700

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-extrabold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-extrabolditalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

// BOLD - 600

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-bolditalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

// SEMI-BOLD - 500

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-semibold.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-semibolditalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

// REGULAR - 400 - normal

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-mediumitalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

// THIN - 300
@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-thin.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-thinitalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

// LIGHT - 200

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-light.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-lightitalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}

// EXTRA-LIGHT - 100

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-extralight.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-extralightitalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}