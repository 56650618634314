//campaign map

.collect-map,
.collect-map__body,
.observation-wrapper-map-form {


    .map-wrapper {
        position: relative;
        height: calc(~"100vw - @{margin} * 2");
        max-height: 60vh;
        border-radius: @margin;

        .map {
            border: 0px;
        }

        @media screen and (min-width: 800px) {
            width: 100%;
        }
    }
}

.main.observation-form {
    .observation-wrapper-map-form .map-wrapper {
        margin: 0px;
    }
}



.minimap {
    margin-top: @margin;

    img {
        width: 100%;
    }
}

.observation-wrapper-map-form {
    margin-top: @margin;
}

@media screen and (max-width: 900px) {

    .collect-map .map-wrapper,
    .observation-wrapper-map-form .map-wrapper {
        margin-bottom: 0px;
    }
}