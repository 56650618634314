.main.no-login {
    .links {
        margin-top: @margin * 2;

        .btn {
            margin-right: @margin;
        }
    }
}

.main.register {

    h1 {
        margin-top: @margin *0.5;
        margin-bottom: @margin * 1.5;
    }

    .field {
        margin-bottom: @margin;

        input {
            margin-top: @margin * 0.3;

            &:invalid {
                border-color: red !important;
                background-color: rgb(255, 255, 232);
            }

            &::placeholder {
                color: @greyMedium
            }
        }
    }

    input[type='checkbox'] {
        padding: @margin;
        padding-top: 0.6rem;
        padding-right: @margin*0.5;
        border: 0px;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .checkbox__wrapper label {
        align-items: flex-start;
    }

    .register-error {
        background-color: @textColor;
        color: @white;
        padding: @margin;
        display: flex;
        margin-bottom: @margin;

        .icon {
            margin-right: @margin;
        }
    }
}

.main.reset-password {
    form {
        margin-top: @margin * 2;

        label span {
            margin-bottom: 0.6em;
        }

        button {
            margin-top: @margin;
        }
    }

    form.confirm {
        .field {
            margin-bottom: @margin;
        }
    }
}