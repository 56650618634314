.footer {
    .flex(center, initial, initial);
    padding: 0 @margin;
    font-size: 0.7rem;
    background: #fff;

        .md * {
            padding: 0px !important;
            margin: 0px !important;
        }


        a {
            color: rgb(82, 82, 82) !important;
            text-decoration: none !important;
            box-shadow: initial !important;
            cursor: default;

            &:hover,
            &:focus {
                text-decoration: underline !important;
            }
        }

        .footerLink {
            // display: flex;
            align-items: center;
            margin-right: 2ch;

            // &:before {
            //     content: '-';
            //     margin: 0 1ch;
            //     display: inline;
            // }
        }
        .csac-credits {
            margin-left: auto;
            .cs-link{
                margin-right: 0.5ch;
            }
            .ac-link {
                margin-left: 0.5ch;
            }
        }
    
}
