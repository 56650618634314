// let's convert all image into grayscale
.main.observation-form-print {
    img {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    .logo-print,
    .logo {
        background-blend-mode: luminosity;
    }
}

// reset general layout

@media screen and (min-width: 900px) {
    .main.observation-form-print {
        width: initial !important;
        padding: initial !important;
        border: 0px !important;
        border-radius: 0px !important;
        margin: initial !important;
    }
}

//layout Page1 and 2

.page-1,
.page-2 {

    width: 29cm * 1.5;
    min-height: 21cm * 1.5;
    margin: 30px;
    padding: 30px;
    transform: scale(0.8);
    transform-origin: top left;
    .shadow();

    @media print {
        transform: initial;
        box-shadow: none;
    }
}


.main.observation-form-print,
.main.observation-form-print *:not(button) {
    position: relative !important;
    overflow: initial !important;
    color: #000 !important;
    border-color: #000 !important;
}


.print-disclaimer {

    padding: @margin*2;
}


.main.observation-form-print {

    .collect__meta,
    .question__number,
    .annotate {
        display: none !important;
    }

    h1 {
        width: initial !important;
        margin: initial !important;
    }


    .md img {
        max-height: 3cm;
        width: auto;
    }


    .page-1,
    .page-2 {
        column-count: 4;
        column-rule: 1px dotted grey;
        column-fill: auto;
        column-gap: 30mm;
    }

    .page-1 {
        page-break-after: always !important;
        height: 21cm !important;


        .logo-print {
            width: 100%;
            height: 15mm;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 10mm;
            column-span: all;
        }

        .collect__description .md p {
            page-break-inside: avoid !important;
        }
    }

    .collect-infos__body {
        padding: 0 !important;
    }

    .question {
        page-break-inside: avoid !important;
        margin-top: 10mm;
    }

    .print-questions {

        >h3 {
            margin-bottom: 4mm;

            &.comment {
                margin-bottom: 40mm;
            }
        }
    }

    .choice__wrapper {
        border: 0px;

        .md {
            margin: 0 !important;
            padding: 0 !important;
        }

    }

    .choice__list {
        margin-top: 2*@margin;
    }

    .choice__label {
        min-height: initial;
        font-size: 1.2rem;
        padding: 0;

        &:before {
            content: ' ';
            width: 0.5cm;
            height: 0.5cm;
            border: 1px solid black;
            border-radius: 1mm;
            margin-right: 1ch;
            display: block;
            flex-shrink: 0;
        }
    }

    .choice__description {
        padding-left: calc(~"0.5cm + 1em");
        font-size: 0.8rem;
    }
}




@media print {

    @page {
        size: A4 landscape;
        margin: 15mm 15mm 15mm 15mm;
        // margin: 0mm 0mm 0mm 0mm;
    }

    * {
        position: relative !important;
        overflow: initial !important;
    }

    html {
        font-size: 12pt;
    }


    .btn-navigate,
    .header,
    .print-disclaimer,
    .add-observation__wrapper,
    .collect-map__wrapper,
    .collect__meta,
    .question__number,
    .annotate,
    .footer {
        display: none !important;
    }


    .main {
        position: relative;
        top: initial;
        right: initial;
        left: initial;
        bottom: initial;
        padding: 0px;
    }

    h1 {
        width: initial !important;
        margin-left: initial !important;
        font-size: 2.2rem !important;
    }
}