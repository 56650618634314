.compose .map-wrapper .scale-and-zoom {
left: -1px;
right: initial;
border-left: 0px;
border-right: @borderDark;
padding-right: 0.5rem;
border-top-right-radius: 0.5rem;
}

.compose .map-wrapper .control-box {
display: none;
}


